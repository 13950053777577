import { Navbar } from '../components/Navbar.jsx'
import React from 'react';
import {
  Flex, Heading, Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx'

export const ProviderLicensureInformation = () => {

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} py={12} px={5} 
        maxW={'1280px'} w={'100%'} margin={'0 auto'} 
        flexDirection={'column'} alignItems={'start'}
        gap={3} 
      >
        <Heading as="h1" size="xl" mb={6} textAlign={'center'} w={'100%'}>Provider Licensure Information (CA, MA, RI, VT)</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>State</Th>
              <Th>Name</Th>
              <Th>License No.</Th>
              <Th>Type</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr><Td>California - CA</Td><Td>Hiren Italia</Td><Td>C-161530</Td><Td>MD</Td><Td>45016</Td></Tr>
            <Tr><Td>California - CA</Td><Td>Mitchel Stotland</Td><Td>A-165176</Td><Td>MD</Td><Td>45199</Td></Tr>
            <Tr><Td>California - CA</Td><Td>Kerelos Tawfeek</Td><Td>A-173223</Td><Td>MD</Td><Td>45107</Td></Tr>
            <Tr><Td>California - CA</Td><Td>Anthony Miranda</Td><Td>C-165064</Td><Td>MD</Td><Td>45199</Td></Tr>
            <Tr><Td>California - CA</Td><Td>Suneer Chander</Td><Td>C-169763</Td><Td>MD</Td><Td>45535</Td></Tr>
            <Tr><Td>California - CA</Td><Td>Alysia Ogburia</Td><Td>C-166898</Td><Td>MD</Td><Td>45260</Td></Tr>

            <Tr><Td>Massachusetts - MA</Td><Td>Hiren Italia</Td><Td>278376</Td><Td>MD</Td><Td>45170</Td></Tr>
            <Tr><Td>Massachusetts - MA</Td><Td>Mitchell Stotland</Td><Td>281631</Td><Td>MD</Td><Td>45444</Td></Tr>
            <Tr><Td>Massachusetts - MA</Td><Td>Suneer Chander</Td><Td>217010</Td><Td>MD</Td><Td>45383</Td></Tr>
            <Tr><Td>Massachusetts - MA</Td><Td>Alysia Ogburia</Td><Td>284329</Td><Td>MD</Td><Td>45142</Td></Tr>

            <Tr><Td>Rhode Island - RI</Td><Td>Hiren Italia</Td><Td>MD16621</Td><Td>MD</Td><Td>45473</Td></Tr>
            <Tr><Td>Rhode Island - RI</Td><Td>Mitchell Stotland</Td><Td>MD16846</Td><Td>MD</Td><Td>45473</Td></Tr>
            <Tr><Td>Rhode Island - RI</Td><Td>Tarsha Darden</Td><Td>MD14777</Td><Td>MD</Td><Td>45473</Td></Tr>

            <Tr><Td>Vermont - VT</Td><Td>Hiren Italia</Td><Td>042-0014329</Td><Td>MD</Td><Td>45626</Td></Tr>
            <Tr><Td>Vermont - VT</Td><Td>Mitchell Stotland</Td><Td>042-0014551</Td><Td>MD</Td><Td>45626</Td></Tr>
            <Tr><Td>Vermont - VT</Td><Td>Rachel Mcleod</Td><Td>101.0135867TELE</Td><Td>NP</Td><Td>45107</Td></Tr>
            <Tr><Td>Vermont - VT</Td><Td>Tarsha Darden</Td><Td>420013018</Td><Td>MD</Td><Td>45626</Td></Tr>
          </Tbody>
        </Table>
      </Flex>
      <Footer />
    </>
  )
}