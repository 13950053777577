import {
  Box, Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue, useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { FiBell } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { categories } from '../data/_categories.ts';
import logoImage from '../images/Symliphy_Logos_WEB_1024 x 500/Symliphy_Logo_3ColorOnLt_1024x500.png';
import { MobileDrawer } from './MobileNavbar';
import { ToggleButton } from './ToggleButton';

const DropdownMenu = ({ title, treatments, onTreatmentClick }) => {

  const navigate = useNavigate();

  // Function to handle treatment click and close the dropdown
   const handleTreatmentSelection = (treatment) => {
    onTreatmentClick(treatment);
  };

  // Sort treatments alphabetically
  const sortedTreatments = [...treatments].sort((a, b) => {
    // Check if either of the items is "General Consult" and prioritize it
    if (a.includes("General Consult")) return -1;
    if (b.includes("General Consult")) return 1;
  
    // If neither item is "General Consult", sort alphabetically
    return a.localeCompare(b);
  });

  // Function to split array into two columns
  const splitArrayIntoTwo = (arr) => {
      const half = Math.ceil(arr.length / 2);
      return [arr.slice(0, half), arr.slice(half)];
  };

  // Check if treatments should be split into two columns
  const isTwoColumns = sortedTreatments.length > 10;
  const [columnOne, columnTwo] = isTwoColumns ? splitArrayIntoTwo(sortedTreatments) : [[], []];

  return (
    <Popover trigger="hover" placement="bottom-start">
      <PopoverTrigger>
        <Button variant="text"
          onClick={() => {
            window.dataLayer.push({
              event: 'category_button_click',
              category: title,
            });
            navigate(`/category/${title.replace(/\s+/g, '-').replace(/'/g, '')}`)
          }
          }
        >{title}</Button>
      </PopoverTrigger>
      <PopoverContent width={isTwoColumns ? '420px' : '240px'}>
        <PopoverBody p={0} boxShadow={'symShadow'}>
          {isTwoColumns ? (
            <SimpleGrid columns={2} spacing={0}>
              <Box>
                {columnOne.map((treatment) => (
                  <Button
                    key={treatment}
                    variant="ghost"
                    w="full"
                    justifyContent="start"
                    onClick={() => handleTreatmentSelection(treatment)}
                  >
                    {treatment}
                  </Button>
                ))}
              </Box>
              <Box>
                {columnTwo.map((treatment) => (
                  <Button
                    key={treatment}
                    variant="ghost"
                    w="full"
                    justifyContent="start"
                    onClick={() => handleTreatmentSelection(treatment)}
                  >
                    {treatment}
                  </Button>
                ))}
              </Box>
            </SimpleGrid>
          ) : (
            <Box>
              {sortedTreatments.map((treatment) => (
                <Button
                  key={treatment}
                  variant="ghost"
                  w="full"
                  justifyContent="start"
                  onClick={() => handleTreatmentSelection(treatment)}
                >
                  {treatment}
                </Button>
              ))}
            </Box>
          )}
        <Box 
          px={2} pb={2} py={3} mt={2}
          w={'100%'} borderBottomRadius={5} color={'white'}
          textAlign={'center'} fontWeight={'bold'}
          onClick={() => 
            {
                window.dataLayer.push({
                  event: 'category_button_click',
                  category: title,
                });
              navigate(`/category/${title.replace(/\s+/g, '-').replace(/'/g, '')}`)
            }
          }
          bgColor={'secondaryBlue.default'} 
          _hover={{'cursor':'pointer', 'opacity':'0.8'}}
        >
          View All {title}
        </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DropdownMenu;

export const Navbar = ({ onButtonClick }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const mobileNavbar = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure(); // for modal
  const { isOpen: isOpenPatientProfile, onOpen: onOpenPatientProfile, onClose: onClosePatientProfile } = useDisclosure(); // Patient Profile Modal
  const navigate = useNavigate();

  const { guid, pharmacy, source } = useUser();

  const handleNavigate = (path) => {
    if(path==='index') { navigate('/')}
  };

  const handleMyAccountOptionsClick = (optionPath) => {
    // Open modal instead of direct navigation for physician follow-up
    if (optionPath === 'Provider Follow-Up') {
      onOpen(); // Opens the modal
    } else {
      onOpenPatientProfile();
    }
  };

  const handlePatientProfile = () => {

    // Pushing an event to the dataLayer on patient profile
    window.dataLayer.push({
      event: 'genie_patient_profile_click',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    window.open('https://app.symliphy.com/apps/rpm/#/101406/auth/login', '_blank');
    onClose();
  };

  const handleCategoryClick = (category) => {
    console.log("clicked category", category)

    // Pushing an event to the dataLayer on category click
    window.dataLayer.push({
      event: 'category_click',
      destination: category,
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    if (category === 'Medication Refill') {
      window.open('/medication-refill','_self');
    } else if (category === 'General Consult') {
      window.open('/general-consult','_self');
    } else if (category === 'Weight Loss') {
      navigate('/treatments/weight-loss',"_self");
    }
  };

  const handleTreatmentClick = (treatment) => {
    const formattedTreatment = treatment
      .toLowerCase() // Convert to lower case
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
  
    // Pushing an event to the dataLayer on treatment click
    window.dataLayer.push({
      event: 'treatment_click',
      destination: formattedTreatment,
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    window.open(`/treatments/${formattedTreatment}`,'_self');
  };

  const handleFindTreatmentClick = () => {

    // Pushing an event to the dataLayer on find treatment click
    window.dataLayer.push({
      event: 'find_treatment_click',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    navigate('/care');
    onClose();
    onClosePatientProfile();
  };
  const handleStartGeneralConsultClick = () => {

    // Pushing an event to the dataLayer on find treatment click
    window.dataLayer.push({
      event: 'start_general_consult_click',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });
    
    navigate('/general-consult');
    onClose();
    onClosePatientProfile();
  };



  return (
    <Box as="section" boxShadow={'symShadow'} zIndex="10" position="relative" className='navbar print-hide'>
      <Flex color={"primaryBlue.default"} position="relative" zIndex="tooltip" justifyContent="center">
        <HStack justify="space-between" w="100vw" px="4">
          <Image src={logoImage} w="200px" onClick={() => handleNavigate('index')} cursor={'pointer'} />
            {isDesktop ? (
              <Flex spacing="3" alignItems="center">
                <Flex size="md" color={"primaryBlue.default"} spacing="1" flexWrap="wrap">
                  {categories.map((category) => {
                    if (['Urgent Care', "Women's Health", "Men's Health", "Pediatric Health"].includes(category.title)) {
                      return (
                        <DropdownMenu key={category.title} title={category.title} treatments={category.treatments} onTreatmentClick={handleTreatmentClick} />
                      );
                    } else if (['Medication Refill', "General Consult", "General Consult - Pediatric"].includes(category.title) || 
                              (category.title === 'Weight Loss' && pharmacy !== 'Giant Eagle')) {
                      return (
                        <Button variant="text" key={category.title} onClick={() => handleCategoryClick(category.title)}>{category.title}</Button>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <Button variant="text" onClick={() => navigate('/care')}>All Services</Button>
                </Flex>
                <Popover >
                  <PopoverTrigger>
                    <Button px={8} ml={4} mr={2} variant={'primaryBlueSolid'}>
                      My Account
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent maxW={'250px'} mr={5}>
                    <PopoverBody>
                      <VStack>
                        <Button w="full" variant="ghost" onClick={() => handleMyAccountOptionsClick('My Patient Profile')}>
                          My Patient Profile
                        </Button>
                        <Button w="full" variant="ghost" whiteSpace="normal" onClick={() => handleMyAccountOptionsClick('Provider Follow-Up')} py={4}>
                          Provider Follow-Up
                        </Button>
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
            ) : (
              <>
                <ToggleButton
                  onClick={mobileNavbar.onToggle}
                  isOpen={mobileNavbar.isOpen}
                  aria-label="Open Menu"
                  color={"primaryBlue.default"}
                  mr={3}
                />
                <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
              </>
            )}
        </HStack>
      </Flex>
      {/* Modal for "Speak to a provider..." */}
      <Modal isOpen={isOpen} onClose={onClose} size={{base:'full',md:"2xl"}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provider Follow-Up</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' fontSize={'1.2em'}>Existing Patients:</Text>
            <Text py={3}>If you have an <b>active case</b> (submitted within the last 72 hours) and wish to follow-up with a provider on a previous consultation, please contact us at 925-460-9191 or email at <a href="mailto:customercare@geniemd.com">customercare@geniemid.com</a>.</Text>
            <Text py={3}>Alternatively, you can login to your patient profile by using the button below. Once in the portal, please click on the bell icon ( <FiBell style={{ display: 'inline', verticalAlign: 'text-bottom' }} /> ) at the top of the screen to review provider messages on your case.</Text>
            <Flex direction={{base:'column',md:'row'}} gap={3}>                     
              <Button variant="primaryOrangeOutline" mr={3} onClick={handlePatientProfile}>
                Visit Your Patient Portal
              </Button>
            </Flex>
            <Text fontWeight='bold' fontSize={'1.2em'} pt={7}>New Patient?</Text>
            <Text py={3}>Welcome! Please
              pick a treatment to get started, or if
              you're unsure, start a general
              consultation to work with one of our
              healthcare providers.</Text>
            <Flex direction={{base:'column',md:'row'}} gap={3}>
              <Button variant="primaryBlueSolid" mr={3} onClick={handleFindTreatmentClick}>
                Find Treatment
              </Button>
              <Button variant="primaryBlueOutline" mr={3} onClick={handleStartGeneralConsultClick}>
                Start General Consultation
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for "Patient Profile" */}
      <Modal isOpen={isOpenPatientProfile} onClose={onClosePatientProfile} size={{base:'full',md:"2xl"}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>My Patient Portal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' fontSize={'1.2em'}>Existing Patients:</Text>
            <Text py={3}>
              To access your patient profile information or to see messages from your provider on an <b>active case</b> (submitted within the last 72 hours), please login using the button below. Once in the portal, please click on the bell icon ( <FiBell style={{ display: 'inline', verticalAlign: 'text-bottom' }} /> ) at the top of the screen to review provider messages on your case.
            </Text>         
            <Flex gap={3} flexDirection={{base:'column', md:'row'}}>
              <Button variant="primaryOrangeSolid" mr={3} onClick={handlePatientProfile}>
                Visit Your Patient Portal
              </Button>
            </Flex>
            <Text fontWeight='bold' fontSize={'1.2em'} pt={7}>New Patient?</Text>
            <Text py={3}>Welcome! Please
              pick a treatment to get started, or if
              you're unsure, start a general
              consultation to work with one of our
              healthcare providers.</Text>
            <Flex gap={3} flexDirection={{base:'column', md:'row'}}>
              <Button variant="primaryBlueSolid" mr={3} onClick={handleFindTreatmentClick}>
                Find Treatment
              </Button>
              <Button variant="primaryBlueOutline" mr={3} onClick={handleStartGeneralConsultClick}>
                Start General Consultation
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClosePatientProfile}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}