import { Flex, Text, Box, Link, Image } from '@chakra-ui/react';

export const TrustPilotReview = () => {
  return (
    <Flex
      as="section"
      background="white"
      pt={{ base: 4, md: 5 }}
      pb={{ base: 4, md: 6 }}
      px={{ base: 4, md: 8 }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      margin="5px auto 0px auto"
    >
      <Link href="https://www.trustpilot.com/review/symliphy.com" isExternal>
        <Flex ml={2} justifyContent={'center'} w="100%" mb="2">
          <Image
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Trustpilot_Logo_%282022%29.svg/512px-Trustpilot_Logo_%282022%29.svg.png"
            alt="Trustpilot"
            alignSelf="center"
            style={{ height: '24px' }}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={2}>
          <Box ml={2}>
            <img
              src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-4.svg"
              alt="Trustpilot"
              style={{ height: '24px' }}
            />
          </Box>
          <Text fontSize="lg" fontWeight="bold" color="black">
            4.0
          </Text>
          <Text fontSize="md" color="gray.500">
            based on 8 reviews
          </Text>
          
        </Flex>
      </Link>
    </Flex>
  );
};
