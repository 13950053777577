export const Questions = [
  { title: 'Can I obtain a prescription without visiting a doctor?', category: 'Prescription Questions', description: 'Legally, a consultation with a healthcare provider is required. Healthcare providers at Symliphy can prescribe new prescriptions and refills through virtual consultations, sending them electronically to your chosen pharmacy.' }, 
  { title: 'Can I get a prescription online?', category: 'Prescription Questions', description: 'You cannot directly order a prescription online. However, you can consult with an online healthcare provider at Symliphy. If prescribed medication is deemed necessary, the healthcare provider will electronically send it to your preferred pharmacy.' }, 
  { title: 'Do online doctors have the authority to prescribe antibiotics?', category: 'Prescription Questions', description: 'Yes, healthcare providers at Symliphy can prescribe antibiotics following a consultation, offering a convenient way to receive treatment from your home.' }, 
  { title: 'What\'s the quickest way to obtain a prescription?', category: 'Prescription Questions', description: 'Fast prescriptions are available through same-day consultations with board-certified healthcare providers at Symliphy. The average consultation lasts 15 minutes, and new prescriptions or refills for non-controlled substances can be provided.' }, 
  { title: 'Is a prescription likely to be provided?', category: 'Prescription Questions', description: 'Your healthcare provider may prescribe medication when medically necessary, excluding narcotics or controlled substances.' }, 
  { title: 'If a prescription is provided, how will I receive it?', category: 'Prescription Questions', description: 'Your provider will electronically send your prescription to the pharmacy of your choice.' }, 
  { title: 'Is it possible to have my prescription sent to a local pharmacy?', category: 'Prescription Questions', description: 'Yes, your provider can send the prescription to any pharmacy you prefer.' }, 
  { title: 'Do online doctors provide effective services?', category: 'Healthcare Provider Questions', description: 'Online doctors at Symliphy are effective for non-emergency conditions, including minor infections, cold and flu, mental health issues, and managing chronic conditions. They provide a convenient option for prescriptions without visiting a physical office.' }, 
  { title: 'Which online doctor service is considered the most reliable or effective?', category: 'Healthcare Provider Questions', description: 'Symliphy is a leading online doctor service, offering care for patients in all 50 states and the District of Columbia.' }, 
  { title: 'Where are the healthcare providers situated?', category: 'Healthcare Provider Questions', description: 'All healthcare providers are located in the U.S., ensuring you receive care from a licensed healthcare provider in your state.' }, 
  { title: 'Will my healthcare provider need information from my primary care provider?', category: 'Healthcare Provider Questions', description: 'No, your responses to the online healthcare questionnaire as part of your Virtual or Live Consultation provide the necessary information for your healthcare provider.  Additional details will be requested if needed. ' }, 
  { title: 'What if I have a follow-up question about my treatment plan?', category: 'Healthcare Provider Questions', description: 'Please contact us at 925-460-9191 or email us at customercare@geniemd.com and we will get you connected with your healthcare provider.' }, 
  { title: 'What should I do if I have a follow-up question for my healthcare provider?', category: 'Healthcare Provider Questions', description: 'Please contact us at 925-460-9191 or email us at customercare@geniemd.com and we will get you connected with your healthcare provider.' }, 
  { title: 'Does Symliphy serve as a replacement for my primary care doctor?', category: 'Healthcare Provider Questions', description: 'No, Symliphy is not a replacement for your primary care doctor. While each consultation is reviewed by licensed healthcare providers, it is important to maintain a face-to-face relationship with your primary care physician.' }, 
  { title: 'Who comprises your team of healthcare providers?', category: 'Healthcare Provider Questions', description: 'All healthcare providers at Symliphy are licensed doctors and registered nurses practicing in states where Symliphy is active.' }, 
  { title: 'Is insurance required for online doctor consultations?', category: 'Payment and Insurance Questions', description: 'No. Symliphy does not currently accept insurance. However, patients with or without insurance can utilize the Symliphy service by paying for the consultation via major debit/credit cards, flexible spending account (FSA) or health savings account (HSA).' }, 
  { title: 'How much does online doctor consultation cost?', category: 'Payment and Insurance Questions', description: 'Costs for online doctor services at Symliphy are $29 for a virtual consultation and $74 for a live consultation.' }, 
  { title: 'Which payment methods are accepted?', category: 'Payment and Insurance Questions', description: 'Symliphy accepts major credit/debit cards, FSAs, and HSAs.' }, 
  { title: 'When will you charge my payment method?', category: 'Payment and Insurance Questions', description: 'Charges occur after you successfully complete the online healthcare questionnaire.  If the system indicates you do not qualify for treatment, you will not incur any charges.' }, 
  { title: 'Is it possible to submit a claim to my health insurance for reimbursement?', category: 'Payment and Insurance Questions', description: 'Submission is possible, but reimbursement isn\'t guaranteed.' }, 
  { title: 'Can I use insurance for prescribed medications?', category: 'Payment and Insurance Questions', description: 'Symliphy does not collect fees for prescribed medications. All medication fees will be handled by your pharmacy of choice. Please check with your pharmacy regarding coverage options.' }, 
  { title: 'Does Symliphy take health insurance?', category: 'Payment and Insurance Questions', description: 'Symliphy currently does not accept health insurance. However, the consultation fee is often cheaper than most copays. In addition, insurance can typically be used for the prescribed medication at your pharmacy.' }, 
  { title: 'Is Symliphy approved for Medicare or Medicaid coverage?', category: 'Payment and Insurance Questions', description: 'No, Symliphy does not currently accept Medicare or Medicaid.' }, 
  { title: 'What is your policy regarding refunds?', category: 'Payment and Insurance Questions', description: 'After completion of a live or virtual consultation, the consultation fee is non-refundable. If you believe there was an error or have additional concerns, please contact customer support via support@symliphy.com' }, 
  { title: 'If I don\'t have health insurance, what options are available to reduce my medication costs?', category: 'Payment and Insurance Questions', description: 'Consider using the Symliphy prescription discount card, which can be found at https://symliphy.com/savings-card' }, 
  { title: 'Does Symliphy comply with HIPAA regulations?', category: 'General Questions', description: 'Yes, Symliphy adheres to HIPAA standards, ensuring the privacy and security of personal medical information through private and secure electronic health record systems.' }, 
  { title: 'Is Symliphy considered a safe platform?', category: 'General Questions', description: 'Yes, Symliphy follows all standard rules and regulations, adhering to both state and federal medical standards. Licensed healthcare providers only prescribe medications if deemed safe and medically appropriate based on the virtual consultation information provided by patients.' }, 
  { title: 'What are the operating hours of Symliphy?', category: 'General Questions', description: 'You can start a virtual consultation 24/7. However, our healthcare providers are available online for live and virtual consultations seven days a week from 9 AM to 9 PM ET.' }, 
  { title: 'Is it necessary to schedule an appointment with Symliphy?', category: 'General Questions', description: 'No, you can start a consultation 24/7 without an appointment.' }, 
  { title: 'Can I take my blood pressure at home if the intake form requires this information?', category: 'General Questions', description: 'Yes, use a blood pressure cuff or other means to measure and input the reading in your intake form.' }, 
  { title: 'How can I proceed if I require a lab test?', category: 'General Questions', description: 'Your Symliphy healthcare provider may issue a lab order for your case. Lab orders are electronically submitted and received from our partner - Quest Diagnostics. To complete a test, please visit your local Quest Location - https://www.questdiagnostics.com/locations/search. Once Quest Diagnostics completes the test, the results will be uploaded to the My Patient Portal section of the Symliphy platform. For more information about the test results, please login to My Patient Portal and click on the Lab Orders and Results tile at the bottom of the screen. ' }, 
  { title: 'How can I provide feedback regarding my experience with Symliphy?', category: 'Support Questions', description: 'Contact Symliphy Customer Support to share your feedback at support@symliphy.com' }, 
  { title: 'What is the process for reaching out to customer service?', category: 'Support Questions', description: 'Contact Symliphy Customer Support to share your feedback at support@symliphy.com' }, 
  { title: 'How does a live consultation work?', category: 'Live Consultation Questions', description: 'Choose your state, create a new account or log into your account, complete an online questionnaire, one of our U.S.-licensed healthcare providers will review it, and contact you directly via phone or video conference to personally discuss your case and recommended treatment options, including a prescription (if appropriate). Please be on the look out for any calls from unknown phone numbers.' }, 
  { title: 'How do I join the live consultation?', category: 'Live Consultation Questions', description: 'You do not have to "join" the live consultation. Once your healthcare provider reviews the information you submitted in the online questionnaire, they will contact you directly via phone or video conference to personally discuss your case and recommended treatment options, including a prescription (if appropriate). Please be on the look out for any calls from unknown phone numbers.' }, 
  { title: 'What if I need to follow up with my healthcare provider?', category: 'Live Consultation Questions', description: 'Please contact us at 925-460-9191 or email us at customercare@geniemd.com and we will get you connected with your healthcare provider.' }, 
  { title: 'How does a virtual consultation work?', category: 'Virtual Consultation Questions', description: 'Choose your state, create a new account or log into your account, complete the online questionnaire, one of our U.S.-licensed healthcare providers will review it, and message you with a treatment plan, including a prescription (if appropriate). In some cases, your healthcare provider may need to obtain more information from you about your case. If so, they will contact you directly via phone or video conference.  Please be on the look out for any calls from unknown phone numbers.' }, 
  { title: 'What if my Symliphy healthcare provider has questions for me about my case?', category: 'Virtual Consultation Questions', description: 'Your Symliphy healthcare provider will reach out to you directly via text, email, phone or video conference. Please be on the look out for messages from your healthcare provider including calls from unknown phone numbers.' }, 
  { title: 'Why can\'t I choose the virtual consultation option?', category: 'Virtual Consultation Questions', description: 'Virtual consultations depend on state regulations. Not all states allow this option.' }, 
  { title: 'What if I need to follow up with my healthcare provider?', category: 'Virtual Consultation Questions', description: 'Please contact us at 925-460-9191 or email us at customercare@geniemd.com and we will get you connected with your healthcare provider.' }, 
  { title: 'What are the benefits of Semaglutide?', category: 'Weight Loss Questions', description: 'Several laboratory studies have demonstrated that Semaglutide can achieve a greater degree of weight loss compared to other treatments. Some of its notable benefits include: Leads to a decrease in BMI*; Not addictive; non-habit-forming medication; Generally well-received by patients; Most patients experience minimal or no side effects; Offers a safe and effective treatment option; Slows down digestion; Decreases appetite and cravings; Rapidly induces feelings of fullness; Achieves substantial weight loss*; *Not guaranteed for all patients' },
  { title: 'What is Semaglutide?', category: 'Weight Loss Questions', description: 'Semaglutide is in a class of medications called incretin mimetics. It helps the pancreas to release the right amount of insulin when blood sugar levels are high. It is a similar molecule to what our bodies make, called a GLP-I analog. It also targets areas in the brain that regulates appetite and food intake. Semaglutide improves body composition by helping patients lose weight and reduce excess fat, including stubborn belly fat.' },
  { title: 'How Does Semaglutide Work?', category: 'Weight Loss Questions', description: 'Semaglutide works in the following ways: Semaglutide delays how quickly our stomachs digest food, leading to a feeling of fullness and satisfaction with smaller meal sizes. The medication slows intestinal motility so you will feel fuller longer after meals. It lowers blood sugars, in part by reducing the production of sugar in the liver and stimulates insulin secretion by the pancreas.' },
  { title: 'How is compounded Semaglutide prescribed?', category: 'Weight Loss Questions', description: 'Semaglutide is injected once a week. Usually prescribed as once a week dosage. The dosage is based on your medical condition and response to treatment. To reduce your risk of side effects, your doctor may direct you to start this medication at a low dose and gradually increase your dose, with the following dose pattern: 0.25mg SQ weekly for I month, 0.5mg SQ weekly for 1 month, 1 mg SQ weekly for 1 month, 1.5mg weekly for 1 month then 2mg weekly thereafter. This medication may be used with or without meals.' },
  { title: 'How do I Inject Semaglutide?', category: 'Weight Loss Questions', description: '1. Clean the area you want to inject with an alcohol wipe and allow the skin to dry.Your abdomen (at least 2 inches away from your belly button), thigh (front or outerthigh), or upper arm are all good injection sites. But choose a spot that is at least a finger&#39;s width away from the last injection site. Change where you give the injection each time. You can inject in the same area of your body each time-just make sure it&#39;snot the same exact spot every time. Moving the injection spot is very important tominimize tissue damage to the area. 2. Hold the syringe with the needle facing toward the ceiling, push the plunger slightly until a drop of liquid forms at the end of needle. 3. Pinch and hold the skin that you will inject. 4. Holding the syringe directly at the pinched skin, quickly push the needle through the skin into the fat tissue, making sure to insert the entire length of the needle. Then, slowly push the plunger to inject all the medication. 5. Pull the needle out and clean the skin with an alcohol wipe. It&#39;s normal to see a drop of blood at the injection spot. Press gently on the site for a few seconds for the  bleeding to stop. Discarding the syringe: Discard the syringe in the sharps container or a heavy plastic container with a tight-fitting lid. Do not throw away the syringe in your household trash.' },
  { title: 'What are the side effects of Semaglutide?', category: 'Weight Loss Questions', description: 'Side effects include swelling/redness/itching at the injection site, tiredness, nausea, vomiting, diarrhea, or constipation can occur. Nausea usually lessens as you continue to use semaglutide. If any of these effects last or get worse, tell your doctor or pharmacist promptly. An allergic reaction is rare but get medical help immediately if you notice rash, itching, swelling (especially of the face/tongue/throat), severe dizziness or trouble breathing. The risk of serious side effects increases in patients with hypoglycemia, kidney problems, and risk of allergic reactions. Note: The 1mg/mL of Cyanocobalamin added to reduce nausea side effects. This is not a complete list of side effects, contact your doctor or pharmacist if you experience any abnormal effects of this medication. Warning: There is a risk of Thyroid C—CeII tumors. If you or any family members have been diagnosed with Multiple Endocrine Neoplasia Syndrome Type 2 or Medullary thyroid cancer you should not take Semaglutide. If you have ever had pancreatitis consult with your medical provider as using Semaglutide can increase the risk of developing pancreatitis.' },
  {
    title: 'How much does the symliphy weight loss program cost?',
    category: 'Weight Loss Questions',
    description: 'Qualify for FREE. Take the free consult and let a qualified provider review your medical history to make sure it is safe for you to take GLP-1 medication. It’s $399 a month for your initial provider consult, monthly provider consults, and medication shipped to your home. At the end of the FREE healthcare questionnaire, if you qualify for treatment, you will be asked to pay for your first month. The first month’s payment includes the healthcare provider consultation with lab order, medication, shipping, and handling of medication to your home. Immediately upon the completion of your healthcare provider’s review of your case, a lab order will be sent to Quest Diagnostics. The lab order and subsequent lab test is to ensure the Weight Loss treatment is safe for you. The lab test will screen for liver, kidney, and thyroid function. Go to your local Quest Diagnostics location to complete the test. https://www.questdiagnostics.com/locations/search. You MUST complete the lab test for your healthcare provider to prescribe a treatment plan. The typical Quest Diagnostics lab fees range from $60 to $80 and are generally required once a year*. *Initial Lab test cost not included in program.'
  },
  {
    title: 'What States do we service?',
    category: 'Weight Loss Questions',
    description: 'At this time, we service 37 states: AK, AZ, CO, CT, DE, GA, HI, IL, IN, IA, ID, KS, KY, LA, MA, MI, MO, MT, NE, NY, NM, NC, ND, OK, OH, OR, PA, RI, SC, SD, TN, UT, VT, VI, WA, WY, WV.'
  },
  {
    title: 'Do I have to make a long-term commitment?',
    category: 'Weight Loss Questions',
    description: 'There\'s no long-term commitment required in our program. It operates on a monthly basis, giving you the flexibility to assess your progress and decide whether to continue or pause your weight loss journey. Each month, a doctor will review your progress to ensure the medication remains safe and effective. You have the freedom to stop whenever you\'re satisfied with your results or choose to continue pursuing your weight loss goals.'
  },
  {
    title: 'Who qualifies?',
    category: 'Weight Loss Questions',
    description: 'To receive a prescription for Semaglutide, patients must first consult with a qualified healthcare provider. Typically, individuals with a BMI of 30 or higher are considered eligible candidates for this medication. However, those with a BMI as low as 27, as well as individuals managing certain medical conditions such as hypertension, type 2 diabetes, and high cholesterol, may also qualify for Semaglutide treatment. During the consultation, the healthcare provider conducts a comprehensive evaluation of the patient\'s medical history and current health status to determine their suitability for Semaglutide treatment. It\'s vital for patients to disclose any history of tumors or cancer, as these factors may influence eligibility. Additionally, patients are required to inform their practitioner about any medications they are currently taking to ensure a safe and tailored treatment plan.'
  },
  {
    title: 'Do you accept insurance?',
    category: 'Weight Loss Questions',
    description: 'At Symliphy Weight Loss, we operate on a cash-only basis. Unfortunately, we do not accept insurance currently. This is because most insurance companies do not cover the medications we prescribe. We aim to provide affordable and accessible weight loss solutions to our clients through our program.'
  },
  {
    title: 'How soon will I receive my medication?',
    category: 'Weight Loss Questions',
    description: 'Once the provider sends the prescription to our certified compounding pharmacy partner you will receive your medication in 1 to 3 days.'
  },
  {
    title: 'Is this confidential?',
    category: 'Weight Loss Questions',
    description: 'Yes. We follow HIPPA rules to protect your private health care information. You can view our Privacy Policy for more information.'
  },
  {
    title: 'Do I need a prescription?',
    category: 'Weight Loss Questions',
    description: 'If you\'re eligible for treatment, our provider team will issue you a prescription.'
  },
  {
    title: 'How do I get a refill?',
    category: 'Weight Loss Questions',
    description: 'To Refill Your Weight Loss Medication, please return to symliphy monthly to complete a new healthcare questionnaire. We recommend completing this step 6 days before you need additional medication.'
  },
  {
    title: 'How do I contact my pharmacy about my Semaglutide prescription?',
    category: 'Weight Loss Questions',
    description: 'Please call (877)858-3784.'
  }
];