import { useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ChatbotComponent } from './components/ChatBot';
import { ScrollToTop } from './components/ScrollToTop';
import { ChatbotProvider } from './contexts/ChatbotContext';
import { UserProvider } from './contexts/UserContext';
import { BrandStory } from './pages/BrandStory';
import { Care } from './pages/Care';
import { Category } from './pages/Category';
import { FAQs } from './pages/FAQs';
import { HIPAAAuthorization } from './pages/HIPAAAuthorization';
import { HowItWorks } from './pages/HowItWorks';
import { Main } from './pages/Main';
import { NoticePrivacyPractices } from './pages/NoticePrivacyPractices';
import { OurStory } from './pages/OurStory';
import { PatientConsent } from './pages/PatientConsent';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { ProviderLicensureInformation } from './pages/ProviderLicensureInformation';
import { SymliphySavingsCard } from './pages/SymliphySavingsCard';
import { Terms } from './pages/Terms';
import { Treatments } from './pages/Treatments';

const RedirectToExternal = ({ url }) => {
  let navigate = useNavigate();
  useEffect(() => {
    window.open(url, '_blank', 'noopener,noreferrer');
    navigate('/');
  }, [url, navigate]);

  return null;
};

export const App = () => {
  return (
    <>
      <ChatbotProvider>
        <ChatbotComponent />
        <ScrollToTop />
        <UserProvider>
          <Routes>
            <Route path="/" element={<Main />} />
            
            <Route path="care" element={<Care />} />
            <Route path="treatments" element={<Treatments />} />
            <Route path="treatments/:treatmentName" element={<Treatments />} />
            <Route path="medication-refill" element={<Treatments />} />
            <Route path="general-consult" element={<Treatments />} />

            {/* Dynamic handling for categories using a single route */}
            <Route path="categories/:categorySlug" element={<DynamicCategoryHandler />} />
            <Route path="category/:categorySlug" element={<DynamicCategoryHandler />} />

            <Route path="how-it-works" element={<HowItWorks />} />

            <Route path="faqs" element={<FAQs />} />
            <Route path="faq" element={<FAQs />} />

            <Route path="symliphy-savings-card" element={<SymliphySavingsCard />} />
            <Route path="savings-card" element={<SymliphySavingsCard />} />
            <Route path="savingscard" element={<SymliphySavingsCard />} />
            <Route path="savings" element={<SymliphySavingsCard />} />

            <Route path="our-story" element={<OurStory />} />
            <Route path="about-us" element={<OurStory />} />
            <Route path="about" element={<OurStory />} />
            <Route path="ourstory" element={<OurStory />} />
            <Route path="aboutus" element={<OurStory />} />

            <Route path="brand-story" element={<BrandStory />} />
            <Route path="brandstory" element={<BrandStory />} />
            <Route path="brand-promise" element={<BrandStory />} />
            <Route path="brandpromise" element={<BrandStory />} />

            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="privacy" element={<PrivacyPolicy />} />

            <Route path="terms-of-use" element={<Terms />} />
            <Route path="terms" element={<Terms />} />
            <Route path="termsofuse" element={<Terms />} />
            <Route path="terms-of-service" element={<Terms />} />
            <Route path="termsofservice" element={<Terms />} />

            <Route path="patient-informed-consent" element={<PatientConsent />} />
            <Route path="patientinformedconsent" element={<PatientConsent />} />
            <Route path="patient-consent" element={<PatientConsent />} />
            <Route path="patientconsent" element={<PatientConsent />} />
            <Route path="consent" element={<PatientConsent />} />
            <Route path="informed-consent" element={<PatientConsent />} />
            <Route path="informedconsent" element={<PatientConsent />} />

            <Route path="notice-of-privacy-practices" element={<NoticePrivacyPractices />} />
            <Route path="noticeofprivacypractices" element={<NoticePrivacyPractices />} />
            <Route path="notice-privacy-practices" element={<NoticePrivacyPractices />} />
            <Route path="noticeprivacypractices" element={<NoticePrivacyPractices />} />
            <Route path="privacy-practices" element={<NoticePrivacyPractices />} />
            <Route path="privacypractices" element={<NoticePrivacyPractices />} />
            <Route path="privacy-practices-notice" element={<NoticePrivacyPractices />} />

            <Route path="provider-licensure-information" element={<ProviderLicensureInformation />} />

            <Route path="hipaa-authorization" element={<HIPAAAuthorization />} />

            <Route path="singlecare" element={<RedirectToExternal url="https://www1.singlecare.com/symliphy" />} />
            <Route path="patient-portal" element={<RedirectToExternal url="https://app.symliphy.com/apps/rpm/#/101406/auth/login" />} />

          </Routes>
        </UserProvider>
      </ChatbotProvider>
    </>
  )
}

const DynamicCategoryHandler = () => {
  const { categorySlug } = useParams();

  let category;

  switch (categorySlug) {
    case 'urgent-care':
    case 'urgentcare':
    case 'urgent':
      category = 'urgent-care';
      break;
    case 'womens-health':
    case 'womenshealth':
    case 'womens':
    case 'women':
    case 'woman':
    case 'female-health':
    case 'femalehealth':
    case 'female':
      category = 'womens-health';
      break;
    case 'mens-health':
    case 'menshealth':
    case 'mens':
    case 'men':
    case 'man':
    case 'male-health':
    case 'malehealth':
    case 'male':
      category = 'mens-health';
      break;
    case 'pediatric-health':
    case 'pediatrichealth':
    case 'pediatric':
    case 'childrens-health':
    case 'childrenshealth':
    case 'children':
    case 'youth-health':
    case 'youthhealth':
    case 'youth':
      category = 'pediatric-health';
      break;
    case 'allergies':
    case 'allergy':
      category = 'allergies';
      break;
    case 'mental-wellness':
    case 'mentalwellness':
    case 'mental-health':
    case 'mentalhealth':
    case 'mental':
      category = 'mental-wellness';
      break;
    case 'dermatology':
      category = 'dermatology';
      break;
    case 'weight-loss': 
      category = 'weight-loss';
      break;
    default:
      category = '';
  }

  return <Category category={category} />;
};