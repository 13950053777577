import {
  Box, 
  Flex, 
  Heading,
  Image,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import { CTABlock } from '../components/CTABlock.jsx';
import { Footer } from '../components/Footer.jsx';
import { Navbar } from '../components/Navbar.jsx';
import { LiveProcess, VirtualProcess, WeightLossProcess } from '../data/_process.ts';
import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';

export const HowItWorks = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultIndex = parseInt(queryParams.get('tab')) || 0;
  const [tab, setTab] = useState(defaultIndex);

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} pt={12} px={5} maxW={'1280px'} w={{base:'100vw',md:'95vw'}} margin={'0 auto'} flexDirection={'column'} alignItems={'center'}>
        <Heading as="h1" size="xl" mb={8}>How It Works</Heading>
        <Tabs isFitted variant={{base:"enclosed-colored",md:"enclosed-colored"}} defaultIndex={defaultIndex}>
          <TabList mb="1em" fontWeight={'bold'}>
            <Tab fontWeight={'bold'} fontSize={{base:'16px',md:'20px'}} onClick={() => setTab(0)}>Virtual Consultation</Tab>
            <Tab fontWeight={'bold'} fontSize={{base:'16px',md:'20px'}} onClick={() => setTab(1)}>Live Consultation</Tab>
            <Tab fontWeight={'bold'} fontSize={{base:'16px',md:'20px'}} onClick={() => setTab(2)}>Weight Loss</Tab>
          </TabList>
          <TabPanels>
            <TabPanel maxW={'1280px'} w={{base:'100vw',md:'95vw'}}>
              {VirtualProcess.map((section, sectionIndex) => (
                <Flex key={section.title} flexDirection={{ base: 'column', md: 'row' }} mb={5} w={'100%'} align="center" gap={4} borderBottom={'solid 1px grey'} pb={6}>
                  <Image src={section.image} objectFit='contain' maxW={{base:'300px',lg:'400px'}} w={'100%'} />
                  <Box w={{ base: '100%', md: '100%' }} mb={{ base: 5, md: 0 }}>
                    <OrderedList spacing={2} pl={5} pt={3}>
                      {section.steps.map((step, stepIndex) => (
                        <ListItem key={stepIndex}>{step}</ListItem>
                      ))}
                    </OrderedList>
                  </Box>
                </Flex>
              ))}
            </TabPanel>
            <TabPanel maxW={'1280px'} w={'100vw'}>
              {LiveProcess.map((section, sectionIndex) => (
                <Flex key={section.title} flexDirection={{ base: 'column', md: 'row' }} mb={5} w={'100%'} align="center" gap={4} borderBottom={'solid 1px grey'} pb={6}>
                  <Image src={section.image} objectFit='contain' maxW={{base:'300px',lg:'400px'}} w={'100%'} />
                  <Box w={{ base: '100%', md: '100%' }} mb={{ base: 5, md: 0 }}>
                    <OrderedList spacing={2} pl={5} pt={3}>
                      {section.steps.map((step, stepIndex) => (
                        <ListItem key={stepIndex}>{step}</ListItem>
                      ))}
                    </OrderedList>
                  </Box>
                </Flex>
              ))}
            </TabPanel>
            <TabPanel maxW={'1280px'} w={{base:'100vw',md:'95vw'}}>
              {WeightLossProcess.map((section, sectionIndex) => (
                <Flex key={section.title} flexDirection={{ base: 'column', md: 'row' }} mb={5} w={'100%'} align="center" gap={4} borderBottom={'solid 1px grey'} pb={6}>
                  <Image src={section.image} objectFit='contain' maxW={{base:'300px',lg:'400px'}} w={'100%'} />
                  <Box w={{ base: '100%', md: '100%' }} mb={{ base: 5, md: 0 }}>
                    <Text fontWeight={'bold'}>{section.title}</Text>
                    <OrderedList spacing={2} pl={5} pt={3}>
                      {section.steps.map((step, stepIndex) => (
                        <ListItem key={stepIndex}>{step}</ListItem>
                      ))}
                    </OrderedList>
                  </Box>
                </Flex>
              ))}
              
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <CTABlock tab={tab} />
      <Footer />
    </>
  );
};
