import {
  Flex, Text, Image, Button
} from '@chakra-ui/react'
  
export const PaymentOptions = () => {

  return (
    <>
      <Flex
        as="section"
        background={'primaryBlue.default'}
        py={{ base: 8, md: 12 }}
        px={{ base: 8, md: 24 }}
        width="100%"
        justifyContent={'space-between'}
        flexDirection={'column'}
        textAlign={'center'}
        alignItems={'center'}
        gap={5}
      >
        <Text fontSize={'30px'} color={'primaryYellow.default'} fontWeight={'bold'}>PRESCRIPTION SAVINGS</Text>

        <Text fontSize={'20px'} color={'white'} fontWeight={'500'} maxW={'1000px'}>You can use your preferred payment method (insurance, HSA, etc.) at your local pharmacy for the prescription provided as part of your treatment plan. Or you can show your pharmacist the Symliphy Savings Card below to save up to 80% at most major retailers.</Text>

        <Flex gap={8} flexDirection={'column'}>
          <Image src="/card.png" w="100%" maxW="500px"></Image>
          <Button variant='primaryOrangeOutline' size='lg'
            onClick={() => window.open('savings','_blank')}
          >Download Rx Savings Card</Button>
        </Flex>

      </Flex>
    </>
  )
}