import {
    Button,
    Flex,
    Text
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TreatmentOptionsWidget = ({ treatmentOptions }) => {
  const [localOptions, setLocalOptions] = useState(treatmentOptions);
  const navigate = useNavigate();

  useEffect(() => {
    // Only set localOptions if it has not already been set
    if (treatmentOptions && treatmentOptions.length > 0 && localOptions.length === 0) {
      setLocalOptions(treatmentOptions);
    }
  }, [treatmentOptions, localOptions]);

  if (!localOptions || localOptions.length === 0) {
    return (
      <Flex flexDirection={'column'} gap={3}>
        <Text>I couldn't find any suggested treatments based on your message.</Text>
        <Text>To connect with a healthcare provider, please start a General Consult.</Text>
        <Button variant={'primaryBlueSolid'} mb={1} onClick={() => navigate(`/general-consult`)}>
          Start General Consult
        </Button>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={'column'} gap={3}>
      <Text>Thanks for sharing. Based on your message, here are potential treatment options available from Symliphy:</Text>
      <Flex flexDirection={'column'} gap={2}>
        {localOptions.slice(0, 3).map((option, index) => (
          <Flex key={index} flexDirection={'column'}>
            <Button variant={'primaryBlueSolid'} onClick={() => window.open(`/treatments/${option.path}`,'_self')}>
              {option.name}
            </Button>
          </Flex>
        ))}
      </Flex>
      <Text>Options listed above are suggestions and are not a medical diagnosis.</Text>
      <Text>To connect with a healthcare provider, select one of the options above, or start a General Consult.</Text>
      <Button variant={'primaryBlueOutline'} mb={1} onClick={() => window.open(`/general-consult`,'_self')}>
        Start General Consult
      </Button>
    </Flex>
  );
};

export default TreatmentOptionsWidget;
