import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { BenefitsBlock } from '../components/BenefitsBlock';
import { FAQsBlock } from '../components/FAQsBlock';
import { Footer } from '../components/Footer';
import { Hero } from '../components/Hero';
import { Navbar } from '../components/Navbar';
import { PaymentOptions } from '../components/PaymentOptions';
import { TreatmentsGrid } from '../components/TreatmentsGrid';

export const Main = () => {
  const treatmentsGridRef = useRef(null);
  const location = useLocation();

  const scrollToTreatmentsGrid = () => {
    treatmentsGridRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('show') === 'treatments') {
      scrollToTreatmentsGrid();
    }
  }, [location]);

  return (
    <>
      <Navbar onButtonClick={scrollToTreatmentsGrid} />
      <Hero onButtonClick={scrollToTreatmentsGrid} />
      <BenefitsBlock onButtonClick={scrollToTreatmentsGrid} />
      <div ref={treatmentsGridRef}><TreatmentsGrid /></div>
      <PaymentOptions />
      <FAQsBlock />
      <Footer />
    </>
  );
};
